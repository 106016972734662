import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/LineaRef',
    name: 'LineaRef',
    component: () => import('../views/LineaRef.vue')
  },
  {
    path:'/PdfGenerator',
    name: 'PdfGenerator',
    component: () => import('../views/PdfGenerator.vue')
  },
  {
    path: '/Catalogo',
    name: 'Catalogo',
    component: () => import('../views/Catalogo.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  //base: process.env.BASE_URL,
  //base: __dirname,
  routes: routes
})

export default router
