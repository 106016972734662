import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@babel/polyfill";
//import 'roboto-fontface/css/roboto/roboto-fontface.css'

import VTooltip from "v-tooltip";
import VueI18n from "vue-i18n";
import FlagIcon from "vue-flag-icon";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VueMaterial from "vue-material";
import "vue-material/dist/vue-material.min.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";

import Vuelidate from "vuelidate";

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

import VueHtml2Canvas from "vue-html2canvas";

import $ from "jquery";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

import VueHover from "vue-hover";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import excel from "vue-excel-export";
import DataTable from "@andresouzaabreu/vue-data-table";

import "bootstrap/dist/css/bootstrap.min.css";
import "@andresouzaabreu/vue-data-table/dist/DataTable.css";

import VueQRCodeComponent from "vue-qrcode-component";

// Register the Vue component
Vue.component("qr-code", VueQRCodeComponent);

import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

import VueJsonPretty from "vue-json-pretty";
Vue.component("vue-json-pretty", VueJsonPretty);

import VueSessionStorage from "vue-sessionstorage";
Vue.use(VueSessionStorage);
Vue.config.productionTip = false;

Vue.component("data-table", DataTable);
Vue.use(excel);
Vue.use(Vuelidate);
Vue.use(VueI18n);
Vue.use(FlagIcon);
Vue.use(VTooltip);
Vue.use(VueCookies);
Vue.use(VueMaterial);
Vue.use(BootstrapVue);
Vue.component(VSwatches);
Vue.use(VueHover);
Vue.use(VueSweetalert2);
Vue.use(VueHtml2Canvas);
Vue.use(VueAxios, axios);
Vue.use(Vuetify);

Vue.config.productionTip = true;

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  data: {
    animation: true,
    position: 0,
    token: null,
    token2: {},
    entidad: [],
    siteX: [],
    dasini: [],
    socket: null,
    myserver: '//gpstuuxan.com:',       //myserver:'//199.89.53.147:',  -> ya no se usa pero lo tengo para recordar la ip del server
    //myserver: "//localhost:",
    mySock: "ws://localhost:", // url socket  -> se autoconfigura
    myport: "9999", // port activo -> se auto configura
    myport1: "9999", // http
    myport2: "9898", // https
    drawer: false,
    group: null,
    versionX: "",
    datos: [],
  },
  created() {
    try {
      this.socket.disconnect();
    } catch (e) {}

    if (window.location.protocol == "https:") {
      this.myserver = "https:" + this.myserver;
      this.myport = this.myport2;
      this.mySock = "wss:" + "//gpstuuxan.com:";
    } else {
      if (this.myserver == "//gpstuuxan.com:") {
        this.mySock = "ws:" + "//gpstuuxan.com:";
      } else {
        //this.mySock = "ws:" + '//localhost:'
      }
      this.myport = this.myport1;
    }

    var vm = this;

    window.onbeforeunload = function(e) {
      vm.socket.disconnect();
    };
  },
  methods: {
    swalAlert: function(icon, title, text) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
      });
    },
    Exit() {
      this.token = null;
      this.animation = false;
      this.dasini = [];

      this.$cookies.remove("user");
      this.$router.push("/");
    },
    SetToken() {
      this.$cookies.remove("user");
      this.$cookies.set("user", this.token);
    },
    post: function(endPoint, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            this.$root.myserver + this.$root.myport + endPoint,
            { data },
            { headers: { "X-Access-Token": this.$root.token.token } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    get: function(endPoint) {
      return new Promise((resolve, reject) => {
        axios
          .get(this.$root.myserver + this.$root.myport + endPoint, {
            headers: { "X-Access-Token": this.$root.token.token },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    https() {
      let http;

      if (this.$root.myserver != "//localhost:") {
        if (window.location.protocol == "https:") {
          http = "https:";
          this.myport = this.myport2;
          if (this.$root.myserver != "https://gpstuuxan.com:") {
            //this.$root.mySock   = http + this.$root.myserver
            this.$root.myserver = http + this.$root.myserver;
          }
        } else {
          http = "http:";
          this.myport = this.myport1;
          if (this.$root.myserver != "http://gpstuuxan.com:") {
            //this.$root.mySock   =  http + this.$root.myserver
            this.$root.myserver = http + this.$root.myserver;
          }
        }
      } else {
        if (this.$root.myserver != "http://localhost:") {
          ///this.$root.mySock   = 'http:' + this.$root.myserver
          this.myport = this.myport1;
          this.$root.myserver = this.$root.myserver;
        }
      }
    },
    numeroALetras: (function() {
      // Código basado en https://gist.github.com/alfchee/e563340276f89b22042a
      function Unidades(num) {
        switch (num) {
          case 1:
            return "UN";
          case 2:
            return "DOS";
          case 3:
            return "TRES";
          case 4:
            return "CUATRO";
          case 5:
            return "CINCO";
          case 6:
            return "SEIS";
          case 7:
            return "SIETE";
          case 8:
            return "OCHO";
          case 9:
            return "NUEVE";
        }

        return "";
      } //Unidades()

      function Decenas(num) {
        let decena = Math.floor(num / 10);
        let unidad = num - decena * 10;

        switch (decena) {
          case 1:
            switch (unidad) {
              case 0:
                return "DIEZ";
              case 1:
                return "ONCE";
              case 2:
                return "DOCE";
              case 3:
                return "TRECE";
              case 4:
                return "CATORCE";
              case 5:
                return "QUINCE";
              default:
                return "DIECI" + Unidades(unidad);
            }
          case 2:
            switch (unidad) {
              case 0:
                return "VEINTE";
              default:
                return "VEINTI" + Unidades(unidad);
            }
          case 3:
            return DecenasY("TREINTA", unidad);
          case 4:
            return DecenasY("CUARENTA", unidad);
          case 5:
            return DecenasY("CINCUENTA", unidad);
          case 6:
            return DecenasY("SESENTA", unidad);
          case 7:
            return DecenasY("SETENTA", unidad);
          case 8:
            return DecenasY("OCHENTA", unidad);
          case 9:
            return DecenasY("NOVENTA", unidad);
          case 0:
            return Unidades(unidad);
        }
      } //Unidades()

      function DecenasY(strSin, numUnidades) {
        if (numUnidades > 0) return strSin + " Y " + Unidades(numUnidades);

        return strSin;
      } //DecenasY()

      function Centenas(num) {
        let centenas = Math.floor(num / 100);
        let decenas = num - centenas * 100;

        switch (centenas) {
          case 1:
            if (decenas > 0) return "CIENTO " + Decenas(decenas);
            return "CIEN";
          case 2:
            return "DOSCIENTOS " + Decenas(decenas);
          case 3:
            return "TRESCIENTOS " + Decenas(decenas);
          case 4:
            return "CUATROCIENTOS " + Decenas(decenas);
          case 5:
            return "QUINIENTOS " + Decenas(decenas);
          case 6:
            return "SEISCIENTOS " + Decenas(decenas);
          case 7:
            return "SETECIENTOS " + Decenas(decenas);
          case 8:
            return "OCHOCIENTOS " + Decenas(decenas);
          case 9:
            return "NOVECIENTOS " + Decenas(decenas);
        }

        return Decenas(decenas);
      } //Centenas()

      function Seccion(num, divisor, strSingular, strPlural) {
        let cientos = Math.floor(num / divisor);
        let resto = num - cientos * divisor;

        let letras = "";

        if (cientos > 0)
          if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
          else letras = strSingular;

        if (resto > 0) letras += "";

        return letras;
      } //Seccion()

      function Miles(num) {
        let divisor = 1000;
        let cientos = Math.floor(num / divisor);
        let resto = num - cientos * divisor;

        let strMiles = Seccion(num, divisor, "UN MIL", "MIL");
        let strCentenas = Centenas(resto);

        if (strMiles == "") return strCentenas;

        return strMiles + " " + strCentenas;
      } //Miles()

      function Millones(num) {
        let divisor = 1000000;
        let cientos = Math.floor(num / divisor);
        let resto = num - cientos * divisor;

        let strMiles = Miles(resto);
        let strMillones = Seccion(num, divisor, strMiles == "" ? "UN MILLON DE" : 'UN MILLON', strMiles == "" ? "MILLONES DE" : 'MILLONES');

        if (strMillones == "") return strMiles;

        return strMillones + " " + strMiles;
      } //Millones()

      return function NumeroALetras(num, currency) {
        currency = currency || {};
        let data = {
          numero: num,
          enteros: Math.floor(num),
          centavos: Math.round(num * 100) - Math.floor(num) * 100,
          letrasCentavos: "",
          letrasMonedaPlural: currency.plural || "PESOS CHILENOS", //'PESOS', 'Dólares', 'Bolívares', 'etcs'
          letrasMonedaSingular: currency.singular || "PESO CHILENO", //'PESO', 'Dólar', 'Bolivar', 'etc'
          letrasMonedaCentavoPlural:
            currency.centPlural || "CHIQUI PESOS CHILENOS",
          letrasMonedaCentavoSingular:
            currency.centSingular || "CHIQUI PESO CHILENO",
        };

        if (data.centavos > 0) {
          data.letrasCentavos =
            "CON " +
            (function() {
              if (data.centavos == 1)
                return (
                  Millones(data.centavos) +
                  " " +
                  data.letrasMonedaCentavoSingular
                );
              else
                return (
                  Millones(data.centavos) + " " + data.letrasMonedaCentavoPlural
                );
            })();
        }

        if (data.enteros == 0)
          return "CERO " + data.letrasMonedaPlural + " " + data.letrasCentavos;
        if (data.enteros == 1)
          return (
            Millones(data.enteros) +
            " " +
            data.letrasMonedaSingular +
            " " +
            data.letrasCentavos
          );
        else
          return (
            Millones(data.enteros) +
            " " +
            data.letrasMonedaPlural +
            " " +
            data.letrasCentavos
          );
      };
    })(),
  },
  render: (h) => h(App),
}).$mount("#app");
